.App {
  height: 100%;
  background-color: #F0F6FF;
}

.rtl {
  direction: rtl;
  text-align: right;
  .arabic-style {
    direction: rtl;
    text-align: right;
  }
}
